import fetch from 'auth/FetchInterceptor';

const haloService = {};

haloService.getDB = function (params) {
  return fetch({
    url: '/getdb',
    method: 'post',
    data: params,
  });
};

haloService.login = function (params) {
  return fetch({
    url: '/login',
    method: 'post',
    data: params,
  });
};

haloService.getDepartments = function (params) {
  return fetch({
    url: '/getdepartments',
    method: 'post',
    data: params,
  });
};

haloService.getProductByDepartment = function (params) {
  return fetch({
    url: '/prodcutsbydepartment',
    method: 'post',
    data: params,
  });
};

haloService.getProductByKeyword = function (params) {
  return fetch({
    url: '/searchproductbyname',
    method: 'post',
    data: params,
  });
};

haloService.getProductModifiers = function (params) {
  return fetch({
    url: '/getmodifiers',
    method: 'post',
    data: params,
  });
};

haloService.postSale = function (params) {
  return fetch({
    url: '/postsale',
    method: 'post',
    data: params,
  });
};

export default haloService;
