import {
  UPDATE_SESSION,
  UPDATE_CART_QTY,
  CLEAR_CART,
  REMOVE_CART_ITEM,
  ADD_CART_ITEM,
  UPDATE_DISCOUNT,
  UPDATE_SHIPPING
} from '../constants/App';

const initState = {
  userid: 0,
  cart: [],
  discount: 0,
  shipping: 0
};

const app = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_SESSION: {
      let newCart = [...state.cart];
      newCart.push(action.payload.cart);
      return {
        ...state,
        userid: action.payload.userid,
        cart: newCart,
      };
    }
    case ADD_CART_ITEM: {
      let addToExistingCart = [...state.cart];
      let productInCart = addToExistingCart.filter(item => {
        return (
          item.product.product_Code === action.payload.cart.product.product_Code
        );
      });
      if (productInCart.length === 0) {
        addToExistingCart.push(action.payload.cart);
        return {
          ...state,
          userid: action.payload.userid,
          cart: addToExistingCart,
        };
      } else {
        let addQtyToExistingProduct = Object.assign(
          [],
          addToExistingCart.map(item => {
            if (
              item.product.product_Code ===
              action.payload.cart.product.product_Code
            ) {
              item.qty = item.qty + 1;
            }
            return item;
          }),
        );
        return {
          ...state,
          cart: addQtyToExistingProduct,
        };
      }
    }
    case UPDATE_CART_QTY: {
      let cart = [...state.cart];
      let updatedCart = Object.assign(
        [],
        cart.map(item => {
          if (item.product.product_Code === action.payload.product_Code) {
            item.qty = action.payload.qty;
          }
          return item;
        }),
      );
      return {
        ...state,
        cart: updatedCart,
      };
    }
    case REMOVE_CART_ITEM: {
      let rmcart = [...state.cart];
      let newCartItemsAfterRemoved = rmcart.filter(item => {
        return item.product.product_Code !== action.payload.product_Code;
      });
      return {
        ...state,
        cart: newCartItemsAfterRemoved,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        userid: action.payload.userid,
        cart: [],
        discount: 0,
        shipping: 0
      };
    }
    case UPDATE_DISCOUNT: {
      return {
        ...state,
        discount: action.payload.discount,
      };
    }
    case UPDATE_SHIPPING: {
      return {
        ...state,
        shipping: action.payload.shipping,
      };
    }
    default:
      return state;
  }
};

export default app;
