/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Modal,
  InputNumber,
  Button,
  Tag,
  notification,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import haloService from '../../services/HaloService';
import { CLEAR_CART } from '../../redux/constants/App';
import { useDispatch } from 'react-redux';

const PaymentModal = props => {
  const dispatch = useDispatch();
  const { cart, discount, shipping, visible, onOk, onCancel } = props;
  const [amountToPay, setAmountToPay] = useState(0);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [payments, setPayments] = useState({
    cash: 0,
    credit: 0,
    check: 0,
    account: 0,
  });

  const branch = JSON.parse(localStorage.getItem('branch'));

  const calculateGrandTotal = () => {
    let total = 0;
    cart.map(record => {
      total += record.product.selling_Price * record.qty;
    });
    total += shipping * 1;
    total += discount * -1;
    return total;
  };

  const setPayment = (type, reset) => {
    if (reset) {
      setPayments({ cash: 0, credit: 0, check: 0, account: 0 });
      setAmountToPay(0);
    } else {
      let totalPayments =
        payments.cash + payments.credit + payments.check + payments.account;
      if (totalPayments >= calculateGrandTotal()) return;
      switch (type) {
        case 'Cash':
          setPayments({
            ...payments,
            cash: reset
              ? 0
              : payments.cash +
                (amountToPay === 0 ? calculateGrandTotal() : amountToPay) * 1,
          });
          break;
        case 'Credit':
          setPayments({
            ...payments,
            credit: reset
              ? 0
              : payments.credit +
                (amountToPay === 0 ? calculateGrandTotal() : amountToPay) * 1,
          });
          break;
        case 'Check':
          setPayments({
            ...payments,
            check: reset
              ? 0
              : payments.check +
                (amountToPay === 0 ? calculateGrandTotal() : amountToPay) * 1,
          });
          break;
        case 'Account':
          setPayments({
            ...payments,
            account: reset
              ? 0
              : payments.account +
                (amountToPay === 0 ? calculateGrandTotal() : amountToPay) * 1,
          });
          break;
      }
    }
  };

  const printReceipt = () => {
    var content = document.getElementById('receiptHidden');
    var pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const onOkPostSale = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let branch = JSON.parse(localStorage.getItem('branch'));

    let saleTotal = calculateGrandTotal();

    let journalHeader = {
      Branch_No: branch.branch_ID,
      Workstation_No: user.workstation_No,
      User_No: user.user_No,
      Invoice_No: 0,
    };

    let items = [];
    cart.map(record => {
      if (record.product.product_ID !== 0) {
        let journalLine = {
          Account_No: '',
          Product_Code: record.product.product_Code,
          Department_No: record.product.department_No,
          Qty: record.qty,
          Ave_Cost: 0,
          Sales_Tax: record.product.sales_Tax,
          Tax_Type: record.product.tax_Type,
          Discount_Amt: 0,
          Dicount_Value: 0,
          Line_Total: record.qty * record.product.selling_Price,
          Function_Key: 7,
          Location: 0,
          Cashup_No: 0,
          Tip: 0,
          Sale_Total: saleTotal,
          Selling_Price: record.product.selling_Price,
        };
        items.push(journalLine);
      }
    });

    let paymentLines = [];
    let alreadyPaid = 0;

    if (payments.cash != undefined && payments.cash !== 0) {
      if (alreadyPaid + payments.cash > saleTotal)
        payments.cash = saleTotal - alreadyPaid.toFixed(2) * 1;
      else alreadyPaid += payments.cash;
      let payment = {
        Account_No: '',
        Product_Code: '',
        Department_No: '',
        Qty: 0,
        Ave_Cost: 0,
        Sales_Tax: 0,
        Tax_Type: 0,
        Discount_Amt: 0,
        Dicount_Value: 0,
        Line_Total: payments.cash,
        Function_Key: 9,
        Location: 0,
        Cashup_No: 0,
        Tip: 0,
        Sale_Total: saleTotal,
        Selling_Price: 0,
      };
      paymentLines.push(payment);
    }
    if (payments.credit != undefined && payments.credit !== 0) {
      if (alreadyPaid + payments.credit > saleTotal)
        payments.credit = saleTotal - alreadyPaid.toFixed(2) * 1;
      else alreadyPaid += payments.credit;
      let payment = {
        Account_No: '',
        Product_Code: '',
        Department_No: '',
        Qty: 0,
        Ave_Cost: 0,
        Sales_Tax: 0,
        Tax_Type: 0,
        Discount_Amt: 0,
        Dicount_Value: 0,
        Line_Total: payments.credit,
        Function_Key: 10,
        Location: 0,
        Cashup_No: 0,
        Tip: 0,
        Sale_Total: saleTotal,
        Selling_Price: 0,
      };
      paymentLines.push(payment);
    }
    if (payments.check != undefined && payments.check !== 0) {
      if (alreadyPaid + payments.check > saleTotal)
        payments.check = saleTotal - alreadyPaid.toFixed(2) * 1;
      else alreadyPaid += payments.check;
      let payment = {
        Account_No: '',
        Product_Code: '',
        Department_No: '',
        Qty: 0,
        Ave_Cost: 0,
        Sales_Tax: 0,
        Tax_Type: 0,
        Discount_Amt: 0,
        Dicount_Value: 0,
        Line_Total: payments.check,
        Function_Key: 11,
        Location: 0,
        Cashup_No: 0,
        Tip: 0,
        Sale_Total: saleTotal,
        Selling_Price: 0,
      };
      paymentLines.push(payment);
    }
    if (payments.account != undefined && payments.account !== 0) {
      if (alreadyPaid + payments.account > saleTotal)
        payments.account = saleTotal - alreadyPaid.toFixed(2) * 1;
      else alreadyPaid += payments.account;
      let payment = {
        Account_No: '',
        Product_Code: '',
        Department_No: '',
        Qty: 0,
        Ave_Cost: 0,
        Sales_Tax: 0,
        Tax_Type: 0,
        Discount_Amt: 0,
        Dicount_Value: 0,
        Line_Total: payments.account,
        Function_Key: 12,
        Location: 0,
        Cashup_No: 0,
        Tip: 0,
        Sale_Total: saleTotal,
        Selling_Price: 0,
      };
      paymentLines.push(payment);
    }
    
    if (paymentLines.length === 0) {
      notification['error']({
        message: 'Error',
        description: 'You have not captured any payments.',
      });
      return;
    }

    let paymentsTotal = 0;
    paymentLines.map(payment => {
      paymentsTotal += payment.Line_Total;
    });

    if (paymentsTotal < saleTotal) {
      notification['error']({
        message: 'Error',
        description: 'The payments are less than the sale total.',
      });
      return;
    }

    let postSale = {
      Database_Name: branch.database_Name,
      Header: journalHeader,
      Items: items,
      Payments: paymentLines,
    };

    haloService
      .postSale(postSale)
      .then(res => {
        if (res.status === 200) {
          setInvoiceNo(res.data.invoice_No);
        } else {
          notification['error']({
            message: 'Error',
            description: res.statusText,
          });
        }
      })
      .catch(err => {
        notification['error']({
          message: 'Error',
          description: JSON.stringify(err),
        });
      });
  };

  const clearSale = () => {
    onOk();
    setPayment('', true);
    dispatch({
      type: CLEAR_CART,
      payload: {},
    });
  };

  useEffect(() => {
    if (invoiceNo !== '') {
      Modal.confirm({
        content:
          'Success! Invoice # ' +
          invoiceNo +
          '\n\n' +
          'Would you like to print a receipt?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: function () {
          printReceipt();
          setTimeout(() => {
            clearSale();
          }, 1000);
        },
        onCancel: function () {
          clearSale();
        },
      });
    }
  }, [invoiceNo]);

  return (
    <Modal
      title="Create Payment"
      visible={visible}
      onOk={onOkPostSale}
      onCancel={onCancel}
      width={600}>
      <Row>
        <Col span={24}>
          <InputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="0.00"
            min={0}
            max={10000000}
            value={amountToPay}
            onChange={e => setAmountToPay(e)}></InputNumber>
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Card
                size={'small'}
                onClick={() => setPayment('Cash', false)}
                hoverable={true}
                headStyle={{
                  backgroundColor: '#22A63C',
                  padding: 0,
                  margin: 0,
                  color: '#ffffff',
                  textAlign: 'end',
                  paddingRight: 10,
                }}
                title={'R ' + payments.cash.toFixed(2)}
                bordered={true}>
                Cash
              </Card>
            </Col>
            <Col span={12}>
              <Card
                size={'small'}
                onClick={() => setPayment('Credit', false)}
                hoverable={true}
                headStyle={{
                  backgroundColor: '#FEC010',
                  padding: 0,
                  margin: 0,
                  color: '#ffffff',
                  textAlign: 'end',
                  paddingRight: 10,
                }}
                title={'R ' + payments.credit.toFixed(2)}
                bordered={true}>
                Credit
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Card
                size={'small'}
                onClick={() => setPayment('Check', false)}
                hoverable={true}
                headStyle={{
                  backgroundColor: '#302EC2',
                  padding: 0,
                  margin: 0,
                  color: '#ffffff',
                  textAlign: 'end',
                  paddingRight: 10,
                }}
                title={'R ' + payments.check.toFixed(2)}
                bordered={true}>
                Check
              </Card>
            </Col>
            <Col span={12}>
              <Card
                size={'small'}
                onClick={() => setPayment('Account', false)}
                hoverable={true}
                headStyle={{
                  backgroundColor: '#696969',
                  padding: 0,
                  margin: 0,
                  color: '#ffffff',
                  textAlign: 'end',
                  paddingRight: 10,
                }}
                title={'R ' + payments.account.toFixed(2)}
                bordered={true}>
                Account
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span="12">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => setPayment('', true)}
                block={true}
                style={{ backgroundColor: '#FF0000', color: '#ffffff' }}>
                Reset
              </Button>
            </Col>
            <Col span="12">
              {/* <Button
                icon={<DeleteOutlined />}
                onClick={() => printReceipt()}
                block={true}
                style={{ backgroundColor: '#00AA00', color: '#ffffff' }}>
                Print
              </Button> */}
            </Col>
          </Row>
        </Col>
        <Col span={11} offset={1}>
          <Card>
            <table width="200">
              <tr>
                <td width="100">Total Quantity</td>
                <td align="right">
                  <Tag color="purple">{cart.length}</Tag>
                </td>
              </tr>
              <tr>
                <td width="100">Order Tax</td>
                <td align="right">
                  $ {(calculateGrandTotal() * 0.15).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td width="100">Discount</td>
                <td align="right">$ {discount && (discount * 1).toFixed(2)}</td>
              </tr>
              <tr>
                <td width="100">Shipping</td>
                <td align="right">$ {shipping && (shipping * 1).toFixed(2)}</td>
              </tr>
              <tr>
                <td colSpan="2">&nbsp;</td>
              </tr>
              <tr>
                <td width="100">
                  <strong>Grand Total</strong>
                </td>
                <td align="right">
                  <strong>$ {calculateGrandTotal().toFixed(2)}</strong>
                </td>
              </tr>
              <tr>
                <td width="100">
                  <strong>Payment</strong>
                </td>
                <td align="right" style={{ color: '#0000aa' }}>
                  <strong>
                    ${' '}
                    {(
                      payments.cash +
                      payments.credit +
                      payments.check +
                      payments.account
                    ).toFixed(2)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td width="100">
                  <strong>Change</strong>
                </td>
                <td align="right" style={{ color: '#00aa00' }}>
                  <strong>
                    ${' '}
                    {payments.cash +
                      payments.credit +
                      payments.check +
                      payments.account -
                      calculateGrandTotal().toFixed(2) <
                    0
                      ? '0.00'
                      : (
                          payments.cash +
                          payments.credit +
                          payments.check +
                          payments.account -
                          calculateGrandTotal().toFixed(2)
                        ).toFixed(2)}
                  </strong>
                </td>
              </tr>
            </table>
            <div id="receiptHidden" style={{ display: 'none' }} class="ticket">
              {/* <div id="receiptHidden" class="ticket"> */}
              <center>
                <img
                  src="/img/logo.png"
                  alt="Logo"
                  style={{ width: 60, height: 60 }}
                />
              </center>
              <p class="centered">
                <center style={{ fontSize: 15, fontWeight: 'bold' }}>
                  {branch.branch_Name}
                </center>
              </p>
              <p class="centered">
                <center style={{ fontSize: 15, fontWeight: 'bold' }}>
                  Invoice: {invoiceNo}
                </center>
              </p>
              <p class="centered" style={{ fontSize: 11 }}>
                <br />
                Address:
                <br />
                Email:
                <br />
                Phone:
                <br />
                Customer: walk-in-customer
              </p>
              <table cellPadding="0" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th style={{ fontSize: 11 }} align="left">
                      Product
                    </th>
                    <th style={{ fontSize: 11 }} align="right">
                      Qty
                    </th>
                    <th style={{ fontSize: 11 }} align="right">
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map(record => {
                    return (
                      <tr>
                        <td style={{ fontSize: 11 }}>
                          {record.product.description}
                        </td>
                        <td style={{ fontSize: 11 }} align="right">
                          {record.qty}
                        </td>
                        <td style={{ fontSize: 11 }} align="right">
                          ${' '}
                          {(record.product.selling_Price * record.qty).toFixed(
                            2,
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colspan="3" style={{ fontSize: 5 }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ fontSize: 11 }} align="right">
                      Tax
                    </td>
                    <td style={{ fontSize: 11 }} align="right">
                      $ 0.00
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ fontSize: 11 }} align="right">
                      Discount
                    </td>
                    <td style={{ fontSize: 11 }} align="right">
                      $ {discount && (discount * 1).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ fontSize: 11 }} align="right">
                      Shipping
                    </td>
                    <td style={{ fontSize: 11 }} align="right">
                      $ {shipping && (shipping * 1).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" style={{ fontSize: 5 }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 11 }} colspan="2">
                      <strong>Grand Total</strong>
                    </td>
                    <td style={{ fontSize: 11 }} align="right">
                      <strong>$ {calculateGrandTotal().toFixed(2)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="centered">
                <center style={{ fontSize: 11 }}>
                  Thank you for your business!
                </center>
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = ({ app }) => {
  const { cart, discount, shipping } = app;
  return { cart, discount, shipping };
};

export default connect(mapStateToProps)(PaymentModal);
