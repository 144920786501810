import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';
// import { useHistory } from "react-router-dom";
import { AUTH_TOKEN } from 'redux/constants/Auth';

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: '/',
  },

  {
    title: 'Account Setting',
    icon: SettingOutlined,
    path: '/',
  },
  {
    title: 'Billing',
    icon: ShopOutlined,
    path: '/',
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: '/',
  },
];

export const NavProfile = () => {
  const [username, setUsername] = useState('');
  // let history = useHistory();

  useEffect(() => {
    if (username === '') {
      let user = JSON.parse(localStorage.getItem('user'));
      setUsername(user.first_Name);
    }
  }, [username]);

  const onSignOut = () => {
    let branch = localStorage.getItem('branchname');
    let franchise = localStorage.getItem('franchise');

    localStorage.removeItem(AUTH_TOKEN);
    // history.push('/auth/login');
    window.location.href = '/auth/login?franchise=' + franchise + "&branch=" + branch;
  };

  const profileImg = '/img/avatars/avatar.png';
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{username}</h4>
            <span className="text-muted">Cashier</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={menuItem.length + 1} onClick={onSignOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
