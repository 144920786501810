/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { connect, useDispatch } from 'react-redux';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_MOBILE_WIDTH,
  SIDE_NAV_DARK,
} from 'constants/ThemeConstant';
import { DeleteOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Table, Row, Col, Input, Button, InputNumber, Modal } from 'antd';
import {
  UPDATE_CART_QTY,
  CLEAR_CART,
  REMOVE_CART_ITEM,
  UPDATE_DISCOUNT,
  UPDATE_SHIPPING,
} from '../../redux/constants/App';
import PaymentModal from './Payment';

const { Sider, Content, Footer } = Layout;

const { confirm } = Modal;

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  isMobile,
  localization = true,
  userid,
  cart,
  cartdiscount,
  cartshipping,
}) => {
  const props = {
    userid,
    cart,
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    isMobile,
    localization,
    cartdiscount,
    cartshipping,
  };
  const dispatch = useDispatch();

  const [paymentVisible, setPaymentVisible] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);

  const tableItemCount = () => {
    if (window.innerHeight < 650) return 3;
    else if (window.innerHeight < 800) return 5;
    else if (window.innerHeight < 960) return 9;
    else if (window.innerHeight < 1100) return 11;
    else if (window.innerHeight < 1400) return 13;
    else return 16;
  };

  const updateCartQty = (record, val) => {
    dispatch({
      type: UPDATE_CART_QTY,
      payload: { product_Code: record.product.product_Code, qty: val },
    });
  };

  const removeCartItem = record => {
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: { product_Code: record.product.product_Code },
    });
  };

  const clearCart = () => {
    confirm({
      title: 'Warning',
      content: 'Are you sure you want to clear the cart?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch({
          type: CLEAR_CART,
          payload: {},
        });
        setDiscount(0);
        setShipping(0);
      },
      onCancel() {},
    });
  };

  const calculateGrandTotal = () => {
    let total = 0;
    props.cart.map(record => {
      total += record.product.selling_Price * record.qty;
    });
    return total;
  };

  const onDiscountChanged = useCallback(
    e => {
      setDiscount(e);
      dispatch({
        type: UPDATE_DISCOUNT,
        payload: {
          discount: e,
        },
      });
    },
    [setDiscount],
  );

  const onShippingChanged = useCallback(
    e => {
      setShipping(e);
      dispatch({
        type: UPDATE_SHIPPING,
        payload: {
          shipping: e,
        },
      });
    },
    [setShipping],
  );

  useEffect(() => {
    setDiscount(cartdiscount);
    setShipping(cartshipping);
  }, [cartdiscount, cartshipping]);

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (_, record) => <span>{record.product.description}</span>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => (
        <span>{record.product.selling_Price.toFixed(2)}</span>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      render: (_, record) => (
        <InputNumber
          step="0.001"
          min={0.001}
          value={record.qty}
          onChange={val => updateCartQty(record, val)}></InputNumber>
      ),
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (_, record) => (
        <span>{(record.product.selling_Price * record.qty).toFixed(2)}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button
          onClick={() => removeCartItem(record)}
          type="primary"
          icon={<DeleteOutlined />}
          style={{ backgroundColor: '#783FF0', borderColor: '#783FF0' }}
          size="small"
        />
      ),
    },
  ];

  return (
    <>
      <Sider
        className={`side-nav ${
          sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''
        }`}
        width={isMobile ? SIDE_NAV_MOBILE_WIDTH : SIDE_NAV_WIDTH}
        collapsed={navCollapsed}>
        <Layout
          style={{
            minHeight:
              window.innerHeight < 700
                ? '75vh'
                : window.innerHeight < 1200
                ? '85vh'
                : '90vh',
          }}>
          {/* <Scrollbars autoHide> */}
          <Content width={isMobile ? SIDE_NAV_MOBILE_WIDTH : SIDE_NAV_WIDTH}>
            <Table
              size={'small'}
              pagination={{
                defaultPageSize: tableItemCount(),
                pageSize: tableItemCount(),
              }}
              columns={columns}
              dataSource={cart}
            />
          </Content>
          <Footer style={{ padding: 10 }}>
            <Row
              style={{
                background: '#6DBDBD',
                padding: 10,
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 18,
                margin: 0,
                flex: 1,
              }}>
              <Col span={24}>
                Grand Total: $ {calculateGrandTotal().toFixed(2)}
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col span={6}>Tax</Col>
              <Col span={6} offset={3}>
                Discount
              </Col>
              <Col span={6} offset={3}>
                Shipping
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <InputNumber
                  disabled={true}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  size="small"
                  placeholder="0.00"
                  min={0}
                  style={{ color: '#000000' }}
                  value={(calculateGrandTotal() * 0.15).toFixed(
                    2,
                  )}></InputNumber>
              </Col>
              <Col span={6} offset={3}>
                <InputNumber
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  size="small"
                  placeholder="0.00"
                  min={0}
                  value={discount}
                  onChange={onDiscountChanged}></InputNumber>
              </Col>
              <Col span={6} offset={3}>
                <InputNumber
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  size="small"
                  placeholder="0.00"
                  min={0}
                  value={shipping}
                  onChange={onShippingChanged}></InputNumber>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col span={11} offset={0}>
                <Button
                  onClick={() => clearCart()}
                  block={true}
                  style={{ backgroundColor: '#E92B34', color: '#ffffff' }}>
                  Reset
                </Button>
              </Col>
              <Col span={11} offset={2}>
                <Button
                  icon={<CreditCardOutlined />}
                  onClick={() => setPaymentVisible(true)}
                  block={true}
                  style={{ backgroundColor: '#783FF0', color: '#ffffff' }}>
                  Pay Now
                </Button>
              </Col>
            </Row>
          </Footer>
          {/* </Scrollbars>       */}
        </Layout>
      </Sider>
      <PaymentModal
        visible={paymentVisible}
        onOk={() => {
          setPaymentVisible(false);
        }}
        onCancel={() => {
          setPaymentVisible(false);
        }}></PaymentModal>
    </>
  );
};

const mapStateToProps = ({ theme, app }) => {
  const { navCollapsed, sideNavTheme } = theme;
  const { userid } = app;
  return {
    navCollapsed,
    sideNavTheme,
    userid,
    cart: app.cart,
    cartdiscount: app.discount,
    cartshipping: app.shipping,
  };
};

export default connect(mapStateToProps)(SideNav);
